<script setup lang='ts'>

import { ref, onBeforeMount, watch } from 'vue'
import { useRoute } from 'vue-router'
import { clickListMenuCategory } from '~/composables/helpersComposable'
const route = useRoute()
const { layoutConfig, layoutState, setActiveMenuItem, onMenuToggle } = useLayout()
const props = defineProps({
	item: {
		type: Object,
		default: () => ({})
	},
	index: {
		type: Number,
		default: 0
	},
	root: {
		type: Boolean,
		default: true
	},
	parentItemKey: {
		type: String,
		default: null
	}
})
const isActiveMenu = ref(false)
const itemKey = ref(null)

onBeforeMount(() => {
	itemKey.value = props.parentItemKey ? props.parentItemKey + '-' + props.index : String(props.index)
	const activeItem = layoutState.activeMenuItem

	isActiveMenu.value = activeItem === itemKey.value || activeItem ? activeItem.startsWith(itemKey.value + '-') : false
})
watch(
	() => layoutConfig.activeMenuItem.value,
	(newVal) => {
		isActiveMenu.value = newVal === itemKey.value || newVal.startsWith(itemKey.value + '-')
	}
)

const itemClick = (event, item) => {
	 console.log('itemClick event: ', event, ' item: ', item)
	if (item.idmenuRif) {
		clickListMenuCategory.value = item.idmenuRif
	}

	if (item.disabled) {
		event.preventDefault()

		return
	}

	const { overlayMenuActive, staticMenuMobileActive } = layoutState

	if ((item.to || item.url) && (staticMenuMobileActive.value || overlayMenuActive.value)) {
		onMenuToggle()
	}

	if (item.command) {
		console.log('item.command: ', item.command)

		item.command({ originalEvent: event, item })
	}

	const foundItemKey = item.items ? (isActiveMenu.value ? props.parentItemKey : itemKey) : itemKey.value

	setActiveMenuItem(foundItemKey)
}

const checkActiveRoute = (item) => {
	return route.path === item.to
}
</script>

<template>
  <li :class="{ 'layout-root-menuitem': root, 'active-menuitem': isActiveMenu }">
    <div v-if="root && item.visible !== false" class="layout-menuitem-root-text mt-3 mb-6">
      {{ item.label }}
    </div>
    <a
      v-if="(!item.to || item.items) && item.visible !== false"
      :href="item.url"
      :class="item.class"
      :target="item.target"
      tabindex="0"
      class="my-2"
      @click="itemClick($event, item, index)"
    >
      <i v-if="item.icon" :class="item.icon" class="layout-menuitem-icon bg-primary" />
      <img v-if="item.img" :src="item.img" :alt="item.label" class="layout-menuitem-icon h-8  mr-4">
      <span class="layout-menuitem-text">{{ item.label }}</span>
      <i v-if="item.items" class="pi pi-fw1 pi-angle-down layout-submenu-toggler bg-primary border-circle w-1 text-center pt-1 text-xl font-light text-white" />
    </a>
    <nuxt-link
      v-if="item.to && !item.items && item.visible !== false"
      class="align-items-center justify-content-start "
      :class="[item.class, { 'active-route': checkActiveRoute(item) }]"
      tabindex="0"
      :to="item.to"
      :target="item.target"
      @click="itemClick($event, item, index)"
    >
      <i v-if="item.icon" :class="item.icon" class="layout-menuitem-icon text-xl" />
      <Icon v-if="item.iconcustom" :name="item.iconcustom" class="layout-menuitem-icon text-xl h8" size="45" />
      <img v-if="item.imageBig" :src="item.imageBig" :alt="item.label" class="layout-menuitem-ic h-12 mr-4">
      <img v-if="item.img" :src="item.img" :alt="item.label" class="layout-menuitem-ico h-8 mr-4">
      <span class="layout-menuitem-text text-white ">{{ item.label }}</span>
      <i v-if="item.items" class="pi pi-fw pi-angle-down layout-submenu-toggler" />
    </nuxt-link>
    <Transition v-if="item.items && item.visible !== false" name="layout-submenu">
      <ul v-show="root ? true : isActiveMenu" class="layout-submenu">
        <app-menu-item
          v-for="(child, i) in item.items"
          :key="child"
          :index="i"
          :item="child"
          :parent-item-key="itemKey"
          :root="false"
        />
      </ul>
    </Transition>
  </li>
</template>

<style lang="scss" scoped></style>
